import React from 'react'

function TwitterMeta({ image, title, url }) {
  return (
    <React.Fragment>
      <meta name="twitter:card" content="photo" />
      <meta name="twitter:site" content="@launchpadlab" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={image} />
    </React.Fragment>

  )
}

export default TwitterMeta
