import { useStaticQuery, graphql } from 'gatsby'

// Use the metadata defined gatsby-config.js
const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteURL
          }
        }
      }
   `
  )

  return site.siteMetadata
}

export default useSiteMetadata
