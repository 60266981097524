import React from 'react'
import PropTypes from 'prop-types'
import { padStart } from 'lodash'
import { pluralize } from '../utils'

const propTypes = {
  time: PropTypes.number,
}

const defaultProps = {
  time: 0,
}

function convertTimeUnits(ms) {
  const seconds = ms / 1000
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = Math.floor(hours / 24)
  const remainingHours = Math.floor(hours % 24)
  const remainingMinutes = Math.floor(minutes % 60)
  const remainingSeconds = Math.floor(seconds % 60)

  const paddedDays = padStart(days, 2, '0')
  const paddedHours = padStart(remainingHours, 2, '0')
  const paddedMinutes = padStart(remainingMinutes, 2, '0')
  const paddedSeconds = padStart(remainingSeconds, 2, '0')

  return {
    daysNum: days,
    days: paddedDays,
    hours: paddedHours,
    minutes: paddedMinutes,
    seconds: paddedSeconds,
  }
}

function CountdownTimer({ time }) {
  const { days, hours, minutes, seconds, daysNum, } = convertTimeUnits(time)
  const inProgress = time < 0 && daysNum <= 0
  return (
    <ul className="countdown" role="timer" aria-live="polite">
      {(time > 0) &&
        <>
          <li>
            <span className="days" aria-labelledby="timeRefDays">{days}</span>
            <p id="timeRefDays">{pluralize(days, 'day')}</p>
          </li>
          <li>
            <span className="hours" aria-labelledby="timeRefHours">{hours}</span>
            <p id="timeRefHours">{pluralize(hours, 'hour')}</p>
          </li>
          <li>
            <span className="minutes" aria-labelledby="timeRefMinutes">{minutes}</span>
            <p id="timeRefMinutes">{pluralize(minutes, 'minute')}</p>
          </li>
          <li>
            <span className="seconds" aria-labelledby="timeRefSeconds">{seconds}</span>
            <p id="timeRefSeconds">{pluralize(seconds, 'second')}</p>
          </li>
        </>
      }
      {inProgress &&
        <>
          <h3 className="in-progress">In Progress<span></span></h3>
          <img alt="Starfield" className="starfield" src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/starfield.gif?1593194907" />
        </>
      }
    </ul>
  )
}

CountdownTimer.propTypes = propTypes
CountdownTimer.defaultProps = defaultProps

export default CountdownTimer
