import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import { isEmpty, compact, } from 'lodash'
import Modal from './modal'
import ImageSlider from './image-slider'

function MissionSummary ({ name, teaser, contributors, url, featured }) {
  const hasMissionDetail = url && featured === 'Y'
  return (
    <>
      <h5 className="project-name">
        {hasMissionDetail ? <Link to={url}>{name}</Link> : name}
      </h5>
      <p className="project-description">{teaser}</p>
      {!isEmpty(contributors) && (
        <div className="contributors">
          <p>{contributors.map(({ name }) => name).join(', ')}</p>
        </div>
      )}
    </>
  )
}

export default ({ launchDate, displayNumber, description, missions, videoLink, media }) => {
  return (
    <div className="demo-day-block">
      <div className="demo-day-container">
        <div className="demo-day-header">
          <h5>{format(launchDate, 'MMMM d, yyyy')}</h5>
          <h2>Demo Day {displayNumber}</h2>
          {description &&
            <p className="subheader">
              {description}
            </p>
          }
          {videoLink &&
            <>
              <label htmlFor={'video-'+displayNumber}>
                <div className="modal-trigger button-white-base video-link">Watch Video</div>
              </label>
              <Modal inputId={'video-'+displayNumber} id={'space-video-'+displayNumber}>
                <iframe width="560" height="315" src={videoLink} frameBorder="0" allowFullScreen />
              </Modal>
            </>
          }
          {media &&
            <>
              <label htmlFor={'image-gallery-'+displayNumber}>
                <div className="modal-trigger button-white-base gallery-link">View Gallery</div>
              </label>
              <Modal inputId={'image-gallery-'+displayNumber}>
                <ImageSlider images={media} />
              </Modal>
            </>
          }
        </div>
        <ul className="project-list">
          {missions.map(({ id, slug, fields: { title, teaser, commander, teamMembers, featured, }}) => { 
            const contributors = compact([commander].concat(teamMembers))
            return (
              <li key={id}>
                <MissionSummary
                  name={title}
                  teaser={teaser}
                  contributors={contributors}
                  url={slug && `/missions/${slug}`}
                  featured={featured}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
