import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { isToday, isFuture } from 'date-fns'
import { sortBy } from 'lodash'
import CountdownTimer from "./countdown-timer"
import { useTimeRemaining } from '../utils'

export default ({ title, hideLink = false }) => {
  const data = useStaticQuery(graphql`
    query DemoDays {
      allWpDemoDay {
        edges {
          node {
            id
            slug
            fields: demo_day_fields {
              displayNumber
              launchDate
              number
            }
          }
        }
      }
    }
  `)

  const demoDays = data.allWpDemoDay.edges.map(({ node }) => node)

  // Find the ongoing (launch is today) or next closest upcoming demo day
  const nextDemoDay = sortBy(demoDays, 'fields.number').find((demoDay) => {
    const { launchDate } = demoDay.fields
    const parsedDate = new Date(launchDate)
    return isToday(parsedDate) || isFuture(parsedDate)
  })
  const { launchDate } = (nextDemoDay?.fields || {})
  const timeRemaining = useTimeRemaining(launchDate)

  return (
    <section className="countdown-wrapper">
      <div className="countdown-container">
        <h2>{title}</h2>
        <CountdownTimer time={timeRemaining} />
        {!hideLink && (
          <div className="link">
            <Link to="/demo_days" className="button-white-base button-arrow">
              Past Demo Days
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}
