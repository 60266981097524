import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'

const propTypes = {
  children: PropTypes.string,
  tag: PropTypes.string,
  addAttrs: PropTypes.array,
}

const defaultProps = {
  tag: 'div',
  addAttrs: [],
}

function RenderedHtml({ children, tag: Tag, addAttrs, ...rest }) {
  if (!children) return null
  return (
    <Tag
      {...rest}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(children, {
          ADD_ATTR: addAttrs,
          FORBID_ATTR: ['style']
        })
      }}
    />
  )
}

RenderedHtml.propTypes = propTypes
RenderedHtml.defaultProps = defaultProps

export default RenderedHtml
