import React from 'react'
import PropTypes from 'prop-types'
import Slider from './slider'

const propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
}

function ImageSlider ({ images, ...rest }) {
  return (
    <Slider {...rest}>
      {images.map(({ sourceUrl, alt }) => (
        <div key={sourceUrl}>
          <img src={sourceUrl} alt={alt} />
        </div>
      ))
      }
    </Slider>
  )
}

export default ImageSlider
