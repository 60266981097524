import React from 'react'
import ExternalLink from './external-link'

export default () => (
  <section className="contact-container">
    <div className="contact-block">
      <h3>Hire Us</h3>
      <p>
        Our mission is to help you grow your business with technology. Are you
        ready to launch something great?
      </p>
      <ExternalLink
        href="https://launchpadlab.com/contact"
        className="button-black-outline button-arrow"
      >
        Get in Touch
      </ExternalLink>
    </div>
    <div className="saturn">
      <p>
        <span>Or</span>
      </p>
    </div>
    <div className="contact-block">
      <h3>Join our Crew</h3>
      <p>
        We are looking to add a few more mission critical people to our team. Do
        you want to be a part of our next big launch?
      </p>
      <ExternalLink
        href="https://launchpadlab.com/careers"
        className="button-black-outline button-arrow"
      >
        Get in Touch
      </ExternalLink>
    </div>
  </section>
)
