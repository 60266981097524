import React from 'react'
import { Helmet } from 'react-helmet'
import SiteHeader from './header'
import SiteFooter from './footer'
import { useSiteMetadata } from '../utils'

import '../assets/stylesheets/application.scss'

const Layout = ({ children }) => (
  <React.Fragment>
    <Helmet title="SPACE by LaunchPad Lab" defer={false} bodyAttributes={{ class: 'index' }} />
    <SiteHeader />
    {children}
    <SiteFooter />
  </React.Fragment>
)

export default Layout
