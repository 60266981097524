import React from 'react'

export default () => (
  <section className="space-gallery">
    <div className="image-block"></div>
    <div className="image-blocks">
      <div className="top-row">
        <div className="image1"></div>
        <div className="image2"></div>
      </div>
      <div className="bottom-row">
        <div className="image1"></div>
        <div className="image2"></div>
      </div>
    </div>
  </section>
)
