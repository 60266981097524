import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  inputId: PropTypes.string.isRequired,
}

// Eventually replace with react-modal
function Modal({ children, inputId, ...rest }) {
 return (
  <div className="modal">
    <input className="modal-state" id={inputId} type="checkbox" />
    <div className="modal-fade-screen">
      <label className="modal-close" htmlFor={inputId}>
        <img alt="Close modal" src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/close.svg?1593194907" />
      </label>
      <div className="modal-inner">
        <div className="modal-content" {...rest}>
          {children}
        </div>
      </div>
    </div>
  </div>
 )
}

Modal.propTypes = propTypes

export default Modal
