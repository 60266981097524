import React from "react"

export default () => (
  <section className="why-space">
    <div className="text-block-container">
      <h2>Why Space?</h2>
      <p className="subheader">
        To unlock our own potential. To challenge ourselves to try new things.
        To be on the leading edges of technology. To create a destination where
        all missions are valuable, regardless of how lofty the goal. To always
        be inspired. To fail, to learn, and to relaunch. To let our personality
        shine through. To be quirky. To have fun.
      </p>
    </div>
  </section>
)
