import React, { useEffect, useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { throttle } from 'lodash'

const SCROLL_OFFSET = 100

function useEventListener(eventName, callback) {
  useEffect(() => {
    window.addEventListener(eventName, callback)
    return () => window.removeEventListener(eventName, callback)
  }, [])
}

export default () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allWpMenuItem {
        edges {
          node {
            label
            url
          }
        }
      }
    }
  `)

  const menuItems = data.allWpMenuItem.edges.map(({ node }) => node)
  const [isScrolled, setIsScrolled] = useState(false)
  const headerClass = isScrolled ? 'topbar-wrapper active' : 'topbar-wrapper'
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const onScroll = useCallback(
    throttle(() => {
      const windowHeight = window.outerHeight
      const scrollHeight = document.documentElement.offsetHeight

      // If there isn't much off screen content, don't activate window
      const isWindowScrollable = scrollHeight - windowHeight > SCROLL_OFFSET / 2
      return setIsScrolled(
        isWindowScrollable && window.pageYOffset > SCROLL_OFFSET
      )
    }, 100),
    []
  )

  useEventListener('scroll', onScroll)

  const toggleNav = (e) => {
    e.preventDefault()
    setIsMobileNavOpen(!isMobileNavOpen)
    console.log("nav")
  }

  return (
    <>
      <header className={headerClass} role="navigation" aria-label="site navigation">
        <nav className="nav-block">
          <ul className="left-nav">
            <a href="/">
              <img alt="logo" src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/space-logo.svg?1593194907" className="nav-logo" />
            </a>
          </ul>
          <div className={`right-nav ${isMobileNavOpen ? 'mobile-menu-active' : ''}`}>
            <ul>
              <li><a className="home-link" href="/">Home</a></li>
              {menuItems.map(({ label, url }) => {
                return (
                  <li key={label}>
                    <a href={`https://launchpadlab.com${url}`}>{label}</a>
                  </li>
                )
              })}
            </ul>
          </div>
        </nav>
      </header>
      <a
        className={`mobile-nav-trigger ${isMobileNavOpen ? 'close' : 'open' }`}
        href="/"
        onClick={toggleNav}
      >
        Menu
      </a>
    </>
  )
}
