import React from 'react'
import PropTypes from 'prop-types'
import SlickSlider from 'react-slick'

const propTypes = {
  children: PropTypes.node.isRequired,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
}

const defaultProps = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

function Slider({ children, dots, infinite, speed, slidesToShow, slidesToScroll, ...rest }) {
 return (
  <SlickSlider
    dots={dots}
    infinite={infinite}
    speed={speed}
    slidesToShow={slidesToShow}
    slidesToScroll={slidesToScroll}
    {...rest}
  >
    {children}
  </SlickSlider>
 )
}

Slider.propTypes = propTypes
Slider.defaultProps = defaultProps

export default Slider
