import React from 'react'
import HireUs from './hire-us'

function Footer() {
  return (
    <React.Fragment>
      <HireUs />
      <footer
        className="site-footer"
        aria-label="site footer"
      >
        <div className="footer-container">
          <div className="logo-block">
            <ul>
              <li>
                <img
                  src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/lpl-logo.svg?1593194907"
                  className="logo"
                  alt="LaunchPad Lab logo"
                />
              </li>
              <li>© 2020 LaunchPad Lab | All Rights Reserved </li>
            </ul>
          </div>

          <div className="navigation-block footer-block">
            <h6>Navigation</h6>
            <span className="header-border"></span>
            <ul>
              <li>
                <a href="http://launchpadlab.com/">Home</a>
              </li>
              <li>
                <a href="http://launchpadlab.com/about">About</a>
              </li>
              <li>
                <a href="http://launchpadlab.com/work">Work</a>
              </li>
              <li>
                <a href="http://launchpadlab.com/process">Process</a>
              </li>
              <li>
                <a href="http://launchpadlab.com/blog">Blog</a>
              </li>
              <li>
                <a href="http://launchpadlab.com/careers">Careers</a>
              </li>
              <li>
                <a href="http://launchpadlab.com/contact">Contact</a>
              </li>
            </ul>
          </div>

          <div className="services-block footer-block">
            <h6>Core Services</h6>
            <span className="header-border"></span>
            <ul>
              <li>
                <a href="http://launchpadlab.com/services#web">
                  Web Design &amp; Development
                </a>
              </li>
              <li>
                <a href="http://launchpadlab.com/services#mobile">
                  Mobile Application Development
                </a>
              </li>
              <li>
                <a href="http://launchpadlab.com/services#ui">
                  UX &amp; UI Design
                </a>
              </li>
              <li>
                <a href="http://launchpadlab.com/services#brand">
                  Logo &amp; Branding
                </a>{" "}
              </li>
              <li>
                <a href="http://launchpadlab.com/services#consulting">
                  Technology Strategy &amp; Consulting
                </a>
              </li>
              <li>
                <a href="http://launchpadlab.com/services#business">
                  Business Development
                </a>
              </li>
            </ul>
          </div>

          <div className="contact-us-block footer-block">
            <h6>Work Together</h6>
            <span className="header-border"></span>
            <ul>
              <li>LaunchPad Lab</li>
              <li>220 W. Huron Street</li>
              <li>Suite 2001</li>
              <li>Chicago, Illinois 60654</li>
              <li>
                <a href="tel:1-773-270-1033">773-270-1033</a>
              </li>
              <li>
                <a href="mailto:contact@launchpadlab.com">
                  contact@launchpadlab.com
                </a>
              </li>
            </ul>
            <ul className="social-media">
              <li>
                <a
                  className="instagram"
                  href="http://instagram.com/launchpadlab"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className="twitter"
                  href="https://twitter.com/launchpadlab"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  className="facebook"
                  href="https://www.facebook.com/LaunchPadLab"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
            </ul>
            <div className="clearfix"></div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
